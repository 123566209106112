@import './reset';
@import './variables';

#root,
body,
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'PF Square Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $black;
  background-color: $grey;
}

* {
  box-sizing: border-box;
  outline: none;

  scrollbar-width: thin;
  scrollbar-color: $orange;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: $tr;
  }

  ::-webkit-scrollbar-thumb {
    background: $orange;
    border-radius: 3px;
  }
}

h1 {
  font-weight: bold;
  font-size: 60px;
  line-height: 60px;
  color: $white;
}

h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: $black;
}

h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: $black;
}

h4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: $black;
}

a {
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #a0a0a0;

  &:hover {
    color: $orange;
  }
}

.container {
  max-width: calc(1032px + (39px * 2));
  width: 100%;
  height: inherit;
  display: block;
  margin: 0 auto;
  transition: all 0.2s;
  padding-left: 39px;
  padding-right: 39px;
  position: relative;

  @include laptop {
    max-width: calc(768px + (39px * 2));
  }

  @include tablet {
    max-width: calc(580px + (39px * 2));
  }

  @include phone {
    max-width: calc(320px + (15px * 2));
    padding-left: 15px;
    padding-right: 15px;
  }
}

.priceInput {
  &__control {
    border-radius: 8px !important;
    min-height: 40px !important;

    &--is-focused {
      border-color: #e0e0e0 !important;
      box-shadow: none !important;
    }

    &--menu-is-open {
      border-color: #828282 !important;

      .priceInput__indicator {
        svg {
          transform: rotate(180deg);
        }

        color: #cccccc !important;
      }
    }
  }
  &__value-container {
    padding: 2px 14px !important;
    font-size: 16px !important;
  }
  &__placeholder {
    font-size: 14px !important;
    color: #828282 !important;
  }
  &__input {
    input {
      color: $black !important;
      font-size: 14px !important;
    }
  }
  &__menu {
    border-radius: 1px !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    position: relative !important;

    &-list {
      padding: 0px 8px;
    }
  }
  &__option {
    color: $black !important;
    font-size: 16px !important;
    line-height: 24px !important;
    height: 40px !important;
    cursor: pointer !important;
    border-radius: 4px;
    &:active {
      background: $orange !important;
    }
    &--is-focused {
      background: $orange !important;
    }
    &--is-active {
      background: $orange !important;
    }
    &--is-selected {
      color: #000 !important;
      cursor: default !important;
      background: $orange !important;
    }
  }
  &__indicator-separator {
    display: none;
  }

  .priceInput__indicator {
    color: #cccccc !important;
  }
}
