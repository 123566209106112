@import '~assets/globalStyle/variables';

.card {
  background: #ffffff;
  border-radius: 12px;
  height: 322px;
  width: 240px;
  padding: 10px;
  transition: 0.2s;
  border: 1px solid $tr;

  &:hover {
    cursor: pointer;
    border: 1px solid $orange;
    transform: scale(1.07);
  }

  .card__art {
    height: 220px;
    width: 220px;
    margin-bottom: 8px;
    position: relative;

    .card__likes {
      position: absolute;
      top: 4px;
      right: 4px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      color: $white;
      width: 40px;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;

      &:hover {
        color: $orange;

        & > div {
          div {
            svg {
              path {
                fill: $orange;
              }
            }
          }
        }
      }

      & > div {
        margin-right: 5px;
        div {
          display: flex;
          align-items: center;
        }
      }

      span {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  .card__artName {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
    font-weight: 400;

    @include phone {
      font-size: 12px;
    }
  }

  .card__collectionName {
    font-size: 12px;
    line-height: 12px;
    color: #636363;
    margin-bottom: 27px;

    @include phone {
      font-size: 10px;
    }
  }

  .card__owner {
    display: flex;
    justify-content: space-between;

    .card__ownerName {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      
      @include phone {
        font-size: 10px;
      }
    }

    .card__ownerValue {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;

      @include phone {
        font-size: 12px;
      }
    }
  }
}
