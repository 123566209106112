@import '~assets/globalStyle/variables';

.catalog {
  padding: 40px 0;

  .catalog__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: $black;
    margin-bottom: 24px;

    @include laptop {
      text-align: center;
      font-size: 28px;
      line-height: 32px;
    }

    @include phone {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  .catalog__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px 24px;
    margin-bottom: 40px;

    @include laptop {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
    }

    @include tablet {
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }

    @include phone {
      grid-template-columns: 1fr;
      grid-gap: 20px;
      justify-items: center;
    }
  }

  .pages {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }

    .point {
      width: 8px;
      height: 8px;
      background: $dark-grey;
      border-radius: 100%;

      &:hover {
        background: #ff7200;
        cursor: pointer;
      }

      &:global(._isActive) {
        background: $orange;
      }
    }
  }
}
