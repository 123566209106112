// Color variables
$orange: #fa801d;
$blue: #00b3eb;
$white: #ffffff;
$black: #000000;
$grey: #f3f3f3;
$dark-grey: #DADADA;
$tr: #ff000000;

// Screen size variables
$desktop: 1280px;
$laptop: 1070px;
$tablet: 850px;
$phone: 640px;

@mixin iphoneX {
  @media only screen and (min-device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
    only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: $laptop) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: $phone) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}

@mixin hd {
  @media only screen and (max-width: 1919px) {
    @content;
  }
}

@mixin uhd {
  @media only screen and (min-width: 2200px) and (max-width: 3839px) {
    @content;
  }
}

// Spaces

// Font family variables
@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Thin.eot');
  src: local('../fonts/PFSquareSansPro-Thin'),
    url('../fonts/PFSquareSansPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFSquareSansPro-Thin.woff2') format('woff2'),
    url('../fonts/PFSquareSansPro-Thin.woff') format('woff'),
    url('../fonts/PFSquareSansPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Bold.eot');
  src: local('../fonts/PFSquareSansPro-Bold'),
    url('../fonts/PFSquareSansPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFSquareSansPro-Bold.woff2') format('woff2'),
    url('../fonts/PFSquareSansPro-Bold.woff') format('woff'),
    url('../fonts/PFSquareSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Regular.eot');
  src: local('../fonts/PFSquareSansPro-Regular'),
    url('../fonts/PFSquareSansPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/PFSquareSansPro-Regular.woff2') format('woff2'),
    url('../fonts/PFSquareSansPro-Regular.woff') format('woff'),
    url('../fonts/PFSquareSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Light.eot');
  src: local('../fonts/PFSquareSansPro-Light'),
    url('../fonts/PFSquareSansPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFSquareSansPro-Light.woff2') format('woff2'),
    url('../fonts/PFSquareSansPro-Light.woff') format('woff'),
    url('../fonts/PFSquareSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PF Square Sans Pro';
  src: url('../fonts/PFSquareSansPro-Medium.eot');
  src: local('../fonts/PFSquareSansPro-Medium'),
    url('../fonts/PFSquareSansPro-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/PFSquareSansPro-Medium.woff2') format('woff2'),
    url('../fonts/PFSquareSansPro-Medium.woff') format('woff'),
    url('../fonts/PFSquareSansPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}