@import '~assets/globalStyle/variables';

.input {
  background: $tr;
  position: relative;

  &::placeholder {
    color: $dark-grey;
  }

  input {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 8px 12px;
    outline: none;
    font-size: 16px;
    border: 1px solid $dark-grey;
    transition: 0.2s;

    &:focus {
      border: 1px solid #828282;
    }
  }

  .error {
    color: #eb5757;
    font-size: 12px;
    line-height: 8px;
    position: absolute;
    bottom: -14px;
    left: 0;
  }

  &:global(._isError) {
    input {
      border: 1px solid #eb5757;
      color: #eb5757;
      color: #eb5757;
    }
  }
}
