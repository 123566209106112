@import '~assets/globalStyle/variables';

.burgerMenu {
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: none;
  grid-area: burger;

  .line {
    background: $black;
    width: 24px;
    height: 2px;
    position: relative;
    border-radius: 2px;
    transition: 0.3s;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      background: $black;
      width: 24px;
      height: 2px;
      transition: 0.4s;
      border-radius: 2px;
    }

    &::after {
      top: 8px;
    }
    &::before {
      top: -8px;
    }
  }

  &:global(._isBlack) {
    .line {
      background: $white;

      &::after,
      &::before {
        background: $white;
      }
    }
  }

  &:global(._isOpen) {
    .line {
      background: $tr;

      &::after {
        top: 0;
        background: $orange;
        transform: rotate(-135deg);
      }
      &::before {
        top: 0;
        background: $orange;
        transform: rotate(-45deg);
      }
    }
  }

  @include phone {
    display: flex;
  }
}
