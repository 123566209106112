@import '~assets/globalStyle/variables';

.checkbox__body {
  position: relative;
  width: 114px;
  height: 40px;
  font-size: 16px;

  .checkbox__text {
    border: 1px solid $orange;
    background-color: $tr;
    border-radius: 8px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  & :checked ~ .checkbox__text {
    background-color: $orange;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
  }
}
