@import '~assets/globalStyle/variables';

.scroll-to-top-button {
  position: fixed;
  bottom: 30px;
  right: 10px;
  z-index: 0;
  transform: translateY(100px);
  transition: transform 0.1s, opacity 0.1s;
  pointer-events: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  @include tablet {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    right: 0px;
  }

  & > div {
    &:first-child {
      margin-right: 8px;

      @include tablet {
        margin-right: 0;
        margin-top: 8px;
      }

      @include phone {
        display: none;
      }
    }
  }

  &.isVisible {
    transform: translateY(0);
    pointer-events: all;
    z-index: 10000;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.8;
  }
}

.scroll-to-top-arrow {
  border-radius: 8px !important;
}
