@import '~assets/globalStyle/variables';

.button {
  border-radius: 8px;
  background-color: $orange;
  cursor: pointer;
  padding: 11px 0;
  min-height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 210px;
  justify-content: center;
  border: none;
  color: $white;
  transition: all 0.1s;

  &:hover {
    background: #ff7200;
  }

  @include laptop {
    width: 140px;
  }

  @include phone {
    height: 34px;
  }

  &:global(._isBlue) {
    background-color: $blue;
  }

  &:disabled {
    background: $dark-grey;
    color: #A0A0A0;
    pointer-events: none;
  }
}
