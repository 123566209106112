@import '~assets/globalStyle/variables';

.arrow {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: $orange;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #ff7200;
  }

  img {
    transform: translateX(-1px);
  }

  &:global(._isLeft) {
    transform: rotate(0deg);
  }

  &:global(._isRight) {
    transform: rotate(180deg);
  }

  &:global(._isTop) {
    transform: rotate(90deg);
  }

  &:global(._isBottom) {
    transform: rotate(270deg);
  }

  @include phone {
    width: 28px;
    height: 28px;
  }
}
