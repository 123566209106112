@import '~assets/globalStyle/variables';

.footer {
  background-color: $white;
  padding-top: 30px;
  padding-bottom: 37px;
  color: $black;
  position: relative;
  z-index: 900;

  .footer__links {
    margin-bottom: 18px;
    display: flex;

    a {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }

    @include phone {
      flex-direction: column;
      align-items: center;

      a {
        margin-right: 0;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .copyrate {
    font-size: 12px;
    
    @include phone {
      text-align: center;
    }
  }
}
