@import '~assets/globalStyle/variables';

.body {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $orange;
  justify-content: space-between;
  transition: border-bottom 0.3s;

  .input {
    background-color: $tr;
    border: none;
    color: $black;
    height: 34px;
    font-size: 16px;
    line-height: 24px;
    width: 90%;
    transition: width 0.2s;

    &::placeholder {
      color: $black;
    }
  }

  .icon {
    cursor: pointer;

    div {
      display: flex;
    }

    &:hover {
      path {
        fill: $orange;
      }
    }
  }

  @include laptop {
    border-bottom: 1px solid $tr;
    width: 23px;
    height: 23px;

    .input {
      display: none;
    }
  }

  &:global(._isBlack) {
    .input {
      color: $white;
      &::placeholder {
        color: $white;
      }
    }

    .icon {
      cursor: pointer;
      & > div {
        width: 24px;
        height: 24px;
      }
      svg {
        path {
          fill: #333333;

          @include laptop {
            fill: $white;
          }
        }
      }

      &:hover {
        path {
          fill: $orange;
        }
      }
    }
  }

  &:global(._isFull) {
    border-bottom: 1px solid $orange;
    width: 100%;
    height: auto;
    .input {
      display: block;
    }
  }
}
