@import '~assets/globalStyle/variables';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $white;
  color: $black;
  z-index: 100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  .body {
    display: grid;
    grid-template: 'logo search menu actions';
    grid-template-columns: 1fr 2fr auto auto;
    align-items: center;
    padding: 20px 0;

    .logo {
      grid-area: logo;

      div {
        display: flex;
        justify-content: start;
      }

      svg {
        height: 40px;
      }

      path {
        &:nth-child(2) {
          fill: $black;
        }
        &:nth-child(3) {
          fill: $black;
        }
        &:nth-child(4) {
          fill: $black;
        }
        &:nth-child(5) {
          fill: $black;
        }
      }
    }

    .search {
      grid-area: search;

      margin-left: 96px;
      margin-right: 114px;
    }

    .menu {
      grid-area: menu;

      margin-right: 50px;

      a {
        font-size: 16px;
        line-height: 24px;
        margin-right: 24px;
        color: $black;
        text-decoration: none;

        &:hover {
          color: $orange;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .actions {
      grid-area: actions;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      .profile {
        margin-right: 21px;

        div {
          display: flex;
          align-items: center;
        }

        &:hover {
          path {
            fill: $orange;
          }
        }
      }

      .wallet {
        div {
          display: flex;
          align-items: center;
        }

        &:hover {
          path {
            fill: $orange;
          }
        }
      }
    }

    @include laptop {
      grid-template: 'logo menu search actions';
      grid-template-columns: 3fr 1fr auto auto;

      .logo {
        svg {
          height: 30px;
          width: 125px;
        }
      }

      .search {
        margin: 0;
        margin-right: 21px;
      }

      .menu {
        margin: 0;
        margin-right: 25px;
      }

      .actions {
      }
    }

    @include phone {
      grid-template: 'logo search actions burger';
      grid-template-columns: 3fr auto auto auto;

      .logo {
        svg {
          height: 25px;
          width: 105px;
        }
      }

      .search {
        margin-right: 21px;
      }

      .menu {
        display: none;
      }

      .actions {
        margin-right: 21px;

        .profile {
          margin-right: 21px;
        }
      }
    }
  }

  &:global(._isBlack) {
    color: $white;
    background-color: rgba(0, 0, 0, 0.6);

    .logo {
      path {
        &:nth-child(2) {
          fill: $white;
        }
        &:nth-child(3) {
          fill: $white;
        }
        &:nth-child(4) {
          fill: $white;
        }
        &:nth-child(5) {
          fill: $white;
        }
      }
    }

    .menu {
      a {
        color: $white;
      }
    }

    .actions {
      .profile {
        path {
          fill: $white;
        }
      }

      .wallet {
        path {
          fill: $white;
        }
      }
    }
  }
}

.openBurger {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  a {
    margin-bottom: 16px;
    text-decoration: none;
    color: $white;
    font-size: 18px;
    line-height: 24px;
  }
}

.searchModal {
  padding-top: 20px;
}
