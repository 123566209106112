@import '~assets/globalStyle/variables';

.tab {
  font-size: 18px;
  line-height: 24px;
  width: 240px;
  cursor: pointer;
  position: relative;
  display: block;
  margin-bottom: 24px;
  z-index: 2;

  .tab__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    width: 240px;
    font-weight: 500;

    .tab__arrow {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(-90deg);
      transition: 0.2s;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .tab__content {
    opacity: 0;
    height: 0;
    transition: 0.1s;
    position: relative;
    z-index: 10;
  }

  &:global(.isOpen) {
    .tab__body {
      margin-bottom: 12px;
    }

    .tab__arrow {
      transform: rotate(0);
    }

    .tab__content {
      opacity: 1;
      height: auto;
    }
  }
}
