@import '~assets/globalStyle/variables';

.section {
  height: 620px;
  background-position: center center;
  background-size: cover;
  color: $white;

  @include phone {
    height: auto;
    padding-bottom: 28px;
    padding-top: calc(71px + 20px);
  }

  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include phone {
      flex-direction: column-reverse;
      justify-content: center;
    }

    .information {
      max-width: 504px;

      .title {
        font-size: 60px;
        line-height: 60px;
        margin-bottom: 8px;
        font-weight: 300;

        @include laptop {
          font-size: 42px;
          line-height: 48px;
        }

        @include phone {
          font-size: 28px;
          line-height: 32px;
          text-align: center;
        }
      }

      .subTitle {
        font-size: 24px;
        line-height: 32px;
        max-width: 240px;
        margin-bottom: 48px;

        @include laptop {
          font-size: 20px;
          line-height: 24px;
        }

        @include phone {
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 24px;
          max-width: fit-content;
        }
      }

      .buttons {
        display: flex;
        align-items: center;

        @include phone {
          justify-content: space-between;
          flex-wrap: wrap;
        }

        @media only screen and (max-width: 316px) {
          justify-content: center;
        }

        & > button {
          margin-right: 24px;

          @include phone {
            margin-right: 0;
          }

          @media only screen and (max-width: 316px) {
            margin-bottom: 6px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .slider {
      display: grid;
      grid-template:
        'arrowleft sliderinfo arrowright'
        'arrowleft pictureinfo arrowright';
      grid-gap: 12px 56px;
      align-items: center;
      width: 416px;

      @include laptop {
        grid-template:
          'sliderinfo sliderinfo sliderinfo'
          'arrowleft pictureinfo arrowright';
        grid-gap: 12px 8px;
        grid-template-columns: 1fr 5fr 1fr;
      }

      @include phone {
        width: auto;
        margin-bottom: 24px;
      }

      .pictures {
        grid-area: sliderinfo;
        display: flex;
        justify-content: center;

        .img {
          img {
            border-radius: 8px;
          }

          @include laptop {
            width: 100%;
          }
        }
      }

      .arrowLeft {
        grid-area: arrowleft;
        img {
          transform: translateX(-20%);
        }

        @include laptop {
          justify-self: baseline;
        }
      }

      .arrowRight {
        grid-area: arrowright;
        img {
          transform: translateX(20%);
        }

        @include laptop {
          justify-self: end;
        }
      }

      .info {
        display: flex;
        grid-area: pictureinfo;

        @include laptop {
          justify-self: baseline;
        }

        .autor_info {
          display: flex;
          align-items: center;

          .avatar {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            margin-right: 8px;

            @include phone {
              width: 28px;
              height: 28px;
            }
          }
        }

        .picture_info {
          .picture_name {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 4px;
          }

          .autor_name {
            font-size: 12px;
            line-height: 12px;
            color: $orange;
          }
        }
      }
    }
  }
}
