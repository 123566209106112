@import '~assets/globalStyle/variables';

.catalog {
  display: grid;
  grid-template-columns: 240px auto;
  padding-top: 110px;
  grid-gap: 0 24px;
  margin-bottom: 10px;

  @include tablet {
    grid-template-columns: auto;
  }

  .filters {
    .checkbox__group {
      display: grid;
      grid-gap: 8px 12px;
      grid-template-columns: 1fr 1fr;
    }

    .priceSelect {
      margin-bottom: 12px;
    }

    .selectLabel {
      display: flex;
      align-items: center;

      & > div {
        div {
          margin-right: 12px;
          display: flex;
          align-items: center;
        }
      }
    }

    .priceBoundaries {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-items: center;
    }

    @include tablet {
      display: none;
    }
  }

  .arts {
    .numberOfArts {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 24px;

      .mobileFilterBtn {
        display: none;
      }

      @include phone {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mobileFilterBtn {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .filtersBtn {
          display: flex;
          align-items: flex-start;
          height: 12px;
          width: 18px;
          margin-right: 12px;
        }

        .filtersBtnLine {
          background: #000000;
          width: 18px;
          height: 2px;
          position: relative;
          border-radius: 2px;
          transition: 0.3s;

          &::after,
          &::before {
            content: '';
            display: block;
            position: absolute;
            background: #000000;
            height: 2px;
            transition: 0.4s;
          }

          &::after {
            width: 12px;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
          }

          &::before {
            width: 6px;
            top: 9px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .gridWrapper {
      overflow-x: visible;
      overflow-y: scroll;
      margin: -14px;
      height: calc(100vh - 257px);

      @include phone {
        height:auto;
        overflow: visible;
      }

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background: $tr;
        border-radius: 3px;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 40px;
      grid-column-gap: 24px;
      margin: 14px;
      justify-items: center;

      @include laptop {
        grid-template-columns: 1fr 1fr;
      }

      @include phone {
        grid-template-columns: 1fr;
      }
    }
  }
}

.scrollToTop {
  right: auto;
  left: 215px;
  position: absolute;

  @include laptop {
    left: calc(240px - 37px);
  }

  @include tablet {
    left: 0;
  }

  @include phone {
    left: auto;
    right: 0;
  }
}
